import React from 'react'
import styled from 'styled-components'
import PlanCard from './PlanCard';
import 'twin.macro'

const StyledDiv = styled.div`
.main-wrapper{
    max-width: 1200px;
    margin: 0 auto;
    background: white;
    padding-top: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0px 12px 6px rgba(0, 0, 0, 0.23);
}
.heading-main{
    font-size: 42px;
    max-width: 481px;
    margin: 0 auto;
    line-height: 1.2;
}
.para{
    max-width: 540px;
}
`

export default function PlanSection({data,page, productVariants }) {

    let finalData= data?.schema_json;  
    let content= {
        // sub_heading: `Selecting the ProLon <br />Plan That’s <span style="color:#fd6d0f"> Right For You</span>`,
        // heading: '',
        // description:'Factors to consider when deciding how often to take ProLon include your health goals, your current health status and if you are working with a Health Care Provider. If working with a Health Care Provider, you should take ProLon as per his or her recommendation.',
        // cardsList: [
        //     {
        //         id: 1,
        //         heading: "Reset With This Short Term Plan For Metabolic Health And Healthy Aging", 
        //         sub_heading: "On-Going Healthy Aging Maintenance Plans", 
        //         description:"Do a quick reset with ProLon. Take ProLon once a month for 3 months in a row to maintain healthy levels of blood glucose, blood pressure, cholesterol, and inflammation, as well to lose excess fat while maintaining lean body mass. Best of all, ProLon provides cell-based renewal for rejuvenation from within!",
        //         heading_color:"#73984a",
        //         price: "$189",
        //         price_text_color: "#9aca37",
        //     },
        //     {
        //         id: 2,
        //         heading: "On-Going Plans To Maintain Metabolic Health And Optimize Healthy Aging", 
        //         sub_heading: "Jumpstart your Metabolic Health and Healthy Aging with the Prolon 5-Day Meal Program", 
        //         description:"Give your body on-going support to maintain healthy levels of blood glucose, blood pressure, cholesterol, and inflammation. You will also get the benefits of cell-based renewal for rejuvenation from within with benefits like smoother, softer skin, more energy and focus, and a trimmer waistline.",
        //         heading_color:"#73984a",
        //         price: "$199",
        //         price_text_color: "#000",
        //     },
        //     {
        //         id: 3,
        //         heading: "On-Going Healthy Aging Maintenance Plans", 
        //         sub_heading: "Reset With This Ultimate Fasting Plan for Metabolic Health and Healthy Aging", 
        //         description:"Support your body’s healthy aging with ProLon. Maintaining your metabolic health is an important step to having a youthful appearance. Additionally, ProLon, provides the unique benefit of cell-based rejuvenation to help you feel and look your best. Turn back the biological clock with ProLon, the only meal program patented for cell-based renewal and clinically tested at The Longevity Institute at USC.",
        //         heading_color:"#73984a",
        //         price: "$224",
        //         price_text_color: "#9aca37",
        //     },
        //   ],
       ...finalData
  }  
    const products = content.cards.map((card) =>{
        return(
            <PlanCard
            key={card.id}
            heading= {card.heading}
            sub_heading={card.sub_heading}
            description={card.description}
            heading_color={card.heading_color}
            cardId={card.id}
            price={card.price}
            price_text_color={card.price_text_color}
            variants={productVariants}
            card={card} 
            />
        )
    })
    return (
        <StyledDiv>
            <div tw="flex flex-col items-center text-center " className="main-wrapper">
                <div tw="flex flex-col items-center text-center ml-2 mr-2 md:ml-56 md:mr-56">
                        <div tw="text-4xl" className="heading-main">
                            <span tw="font-bold" dangerouslySetInnerHTML={{ __html: content.heading }}></span> 
                        </div>
                        <div tw="mt-2 md:mt-6 px-2" className="para">
                            <p>{content.description}</p>
                        </div>
                </div>
            <div tw="flex flex-row justify-around items-center flex-wrap w-11/12">
                {products}
            </div>
            </div>
        </StyledDiv>
    )
}

