import React, { useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash';
import Store from 'src/stores/common-store';
import 'twin.macro'

const StyledDiv = styled.div`
width: 32%;
margin: 0 auto;
min-width:320px;
margin-bottom: 30px;
.heading{
	height: 130px;
	@media only screen and (max-width:1200px) {
		height: 170px;
	}
	@media only screen and (max-width:768px) {
		height: auto;
	}
}
.description-class{
	min-height: 290px;
	@media only screen and (max-width:768px) {
		height: auto;
		min-height: auto;
	}
}
.green-text{
	color:#73984a;
  }
  .light-green{
    color:#9aca38;
  }
  .text-orange{
    color:#fd6d0f;
  }
  .card-border{
    border:1px solid black;
  }
  .border-top{
    border-top: 1px solid #d8d8d8;
  }
  .card{
      flex:1;
  }

.background-orange{
    background-color:#fd6d0f;
  }
  .cartButton {
    background: #fd6d0f;
    min-width: 140px;
    outline: none;
  }
`
export default function PlanCard(props) {

  let { variants } = props;

  const [flavour, setflavour] = useState('Original');
  const [adding, setadding] = useState(false);
  return (
    <StyledDiv>


      <div tw="flex flex-col mt-12" className="card-border">
        <div className="custom-height">
          <div tw="text-2xl font-semibold px-2 pt-4 text-left" className="green-text heading">
            <span>{props.heading}</span>
          </div>
          <div tw="px-5 pt-1 flex items-start text-left" className="description-class">
            <p>{props.description}</p>
          </div>
        </div>
        <div tw="flex flex-col items-center" className="border-top">

          <div tw="text-4xl font-bold " className="light-green">
            <span style={{ color: `${props.price_text_color}` }}>{props.price}</span>

          </div>
          <div tw="mt-2"><span>Per box</span>
          </div>
        </div>

        <div tw="text-center font-bold p-2 h-20 flex justify-center items-center" className="border-top">
          <p>{props.sub_heading}</p></div>

        <div className="border-top">
          <div tw="flex justify-evenly items-center justify-center content-center h-12 ">
            <span tw="flex items-center justify-center">
              <input
                type="radio"
                name={'radio19' + props.cardId}
                id={'test19' + props.cardId}
                checked={flavour == 'Original' ? true : false}
                onClick={() => {
                  setflavour('Original');
                }}
              />
              <label for={"test19" + props.cardId} tw="text-xs font-medium tracking-widest text-center px-2 mt-2">ORIGINAL</label>
            </span>
            <span tw="flex items-center justify-center">
              <input
                type="radio"
                name={'radio19' + props.cardId}
                id={'test29' + props.cardId}
                checked={flavour == 'New Flavors' ? true : false}
                onClick={() => {
                  setflavour('New Flavors');
                }}
              />
              <label for={"test29" + props.cardId} className="text-xs font-medium tracking-widest text-center px-2 mt-2">NEW FLAVORS</label>
            </span>
          </div>
        </div>
        <div tw="flex justify-center items-center mb-4">
          <button
            tw="text-white p-3  text-xs font-bold text-center px-8"
            className={`cartButton${adding ? 'btn--loading' : ''}`}
            onClick={async () => {
              setadding(true);
              let quantity = props.card.quantity;
              let newVariants = variants.map((e) => {
                let { selectedOptions } = e;
                if (selectedOptions && selectedOptions.length > 0) {
                  selectedOptions.forEach((option) => {
                    e[option.name] = option.value;
                  });
                }

                return e;
              });
              let variant = _.find(newVariants, (e) => {
                return e['Soup Flavor'] == flavour && e['Quantity'] == quantity;
              });

              let variantId = variant.shopifyId;

              let data = { variantId, quantity: 1, customAttributes: [] };
              if (props.card.subscription) {
                let attr = [
                  {
                    key: 'subscription',
                    value: 'true',
                  },
                  {
                    key: 'shipping_interval_unit_type',
                    value: 'Months',
                  },
                  {
                    key: 'shipping_interval_frequency',
                    value: '1',
                  },
                ];
                data.customAttributes = attr;
              }
              await Store.addItem(data);
              Store.showCart();

              setadding(false);
            }}
          >
            {adding ? 'Adding...' : 'ADD TO CART'}
          </button>			</div>
      </div>
    </StyledDiv>
  )
}
